import { IDfaFront, preStatuses } from '@dltru/dfa-models'
import { FC } from 'react'

import { Divider } from '../../../Divider'
import { getDateHelper } from '../../../utils'
import { Milestone } from '../groupData'
import { DecisionHeader } from './DecisionHeader'
import style from './style.m.less'

export interface IDecisionBlockProps {
    dfa: IDfaFront
    LinksComponent?: FC
}

export const DecisionBlock: FC<IDecisionBlockProps> = ({ dfa, LinksComponent }) => {
    return (
        <div className={style.emissionDecisionCard}>
            <DecisionHeader dfa={dfa} balance={dfa.balance} />
            <div className={style.cardBody}>
                <div className={style.cardRow}>
                    <Milestone value={getDateHelper(dfa?.upload_date)} label="Подано" />
                    {!preStatuses.includes(dfa?.status) && (
                        <Milestone value={getDateHelper(dfa?.handle_date)} label="Согласовано" />
                    )}
                    <Milestone value={getDateHelper(dfa?.issue_date)} label="Начало торгов" />
                    <Milestone value={getDateHelper(dfa?.redeem_date)} label="ЦФА будут погашены" />
                </div>
                <Divider />
                {LinksComponent && <LinksComponent />}
            </div>
        </div>
    )
}
